export const street = [
  {
    key:1,
    name: 'Васильковая',
    color: 'orange'
  },
  {
    key:2,
    name: 'Кедровая',
    color: 'olive'
  },
  {
    key:3,
    name: 'Каштановая',
    color: 'orange'
  },
  {
    key:4,
    name: 'Сосновая',
    color: 'olive'
  },
  {
    key:5,
    name: 'Ореховая',
    color: 'orange'
  },
  {
    key:6,
    name: 'Лесная',
    color: 'olive'
  }
]
