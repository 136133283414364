<script setup>
import { useIMask } from 'vue-imask'
import { useToast } from 'vue-toastification'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'
import basicInfoService from '../services/basic-info.service'
import clientService from '../services/client.service'
import { ref, computed, onMounted } from 'vue'

const toast = useToast()

const form = ref({
  phone: '',
  street: '',
  numberHome: 0
})

const mask = useIMask({
  mask: Number,
  radix: '.'
});

const phone = ref('')
const maskPhone = {
  mask: '+{7}(000) 000-0000',
  lazy: true
}
const street = ref([])
const numberHome = ref([1])
const number = ref(0)

const validations = computed(() => {
  const localRules = {
    phone: {
      required: helpers.withMessage('Обязательно к заполнению', required),
      minLength: helpers.withMessage('10 знаков', minLength(10))
    },
    street: {
      required: helpers.withMessage('Выбрать улицу', required)
    }
  }
  return localRules
})

const v$ = useVuelidate(validations, form)

onMounted(async () => {
  street.value = await getStreet()
})

async function checkin () {
  const isFormCorrect = await v$.value.$validate()

  if (!isFormCorrect) return

  const {
    success,
    errors
  } = clientService.post(form.value)
  if (success === false) {
    this.toast.error('Запрос не получен, попробуйте еще раз', {
      timeout: 5000
    })
  } else {
    toast.success('Запрос получен, ожидайте ответа', {
      timeout: 5000
    })
  }
}

function getStreet () {
  return basicInfoService.getAllStreet()
}

async function getHomeStreet (number) {
  numberHome.value = await basicInfoService.getAllHome(number)
}

</script>

<template>
  <main class="login-page">
    <div class="login-box form-signin m-auto">
      <div class="card card-info">
        <div class="card-header">
          <h3 class="card-title">
            Запросить доступ
          </h3>
        </div>
        <form @submit.prevent="checkin">
          <div class="card-body">
            <div class="input-group mb-3">
              <div class="card-subtitle">
                Введите телефон
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-phone" />
                  </span>
                </div>
                <input
                  class="form-control"
                  v-model="form.phone"
                  ref="mask"
                  name="phone"
                  placeholder="+7"
                  @accept="onAccept"
                  @complete="onComplete"
                >
                <div
                  class="input-errors"
                  v-if="v$.phone.$dirty && v$.phone.required.$invalid"
                >
                  <div class="error-msg">
                    {{ v$.phone.required.$message }}
                  </div>
                </div>
                <div
                  class="input-errors"
                  v-if="v$.phone.$dirty && v$.phone.minLength.$invalid"
                >
                  <div class="error-msg">
                    Телефон из 10 цифр
                  </div>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <div class="card-subtitle">
                Выбрать улицу
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-code" />
                  </span>
                </div>
                <select
                  v-model="form.street"
                  name="street"
                  class="form-control"
                  @change="getHomeStreet(form.street)"
                >
                  <option value="0">Нет улицы</option>
                  <option
                    v-for="item in street"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <div
                  class="input-errors"
                  v-if="v$.street.$dirty && v$.street.required.$invalid"
                >
                  <div class="error-msg">
                    {{ v$.street.required.$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <div class="card-subtitle">
                Выбрать номер дома
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-code" />
                  </span>
                </div>
                <select
                  class="form-control"
                  v-model="number"
                >
                  <option
                    v-for="num in numberHome"
                    :key="num.home"
                    :value="num.home"
                  >
                    {{ num.home }}
                  </option>
                </select>
              </div>
            </div>
            <div class="input-group mb-3">
              <button class="btn btn-info w-100">
                Отправить
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
.input-errors {
  width: 100%;
  color: red;
}
</style>
