<script setup>
import Navbar from '@/components/Navbar'
import listService from '../services/list.service'
import { ref, onMounted } from 'vue'

const globalList = ref([])

onMounted (async () => {
  globalList.value = await listService.get()
})

</script>

<template>
  <section class="container-fluid">
    <navbar />
    <div class="row">
      <div class="col-sm-12">
        <table class="table table-bordered table-striped dataTable dtr-inline">
          <tr>
            <th>Кадастровый номер</th>
            <th>Улица</th>
            <th>Дом</th>
            <th>Размер</th>
          </tr>
          <tr
            v-for="(one, index) in globalList"
            :key="index"
            class="odd"
          >
            <td>{{ one.number }}</td>
            <td>{{ one.street_id }}</td>
            <td>{{ one.home }}</td>
            <td>{{ one.size }}</td>
          </tr>
        </table>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
