<script setup>
import navbar from '@/components/Navbar'

const user = {
  name: 'Alex'
}
</script>


<template>
  <section class="container-fluid">
    <navbar />
    <div class="content-header">
      <div class="row">
        <div class="col-sm-3">
          <div class="card card-info">
            <div class="card-header">Профиль</div>
            <div class="card-body">{{ user.name }}</div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card card-success">
            <div class="card-header">Актуальные сборы денег</div>
            <div class="card-body">2</div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card card-success">
            <div class="card-header">Голосование</div>
            <div class="card-body">2</div>
          </div>
        </div>
        <div class="col-sm-3" />
      </div>
    </div>
  </section>
</template>


