<template>
  <nav class="navbar navbar-expand navbar-info navbar-dark">
    <ul class="navbar-nav">
      <li
        v-for="(link, index) in links"
        :key="index"
      >
        <router-link
          :to="link.url"
          class="nav-link"
        >
          <span v-html="link.icon" />
          {{ link.title }}
        </router-link>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item ">
        <a
          href="#"
          class="p-1"
        >
          <i class="fa fa-user text-white" />
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#"
          class="p-1"
          @click="logout"
        >
          <i class="fa fa-sign-out-alt text-white" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import authService from '../services/auth.service'
import { useToast } from 'vue-toastification'

export default {
  name: 'Navbar',
  setup () {
    const toast = useToast()
    return {
      toast
    }
  },
  data () {
    return {
      links: [
        {
          key: 0,
          icon: '<i class="fas fa-house-user"/>',
          title: '',
          url: '/'
        },
        {
          title: 'Список всех',
          url: '/list',
          icon: ''
        },
        {
          title: 'Финансы',
          url: '/finance',
          icon: ''
        },
        {
          title: 'Опросы',
          url: 'voting',
          icon: ''
        },
        {
          title: 'Карта поселка',
          url: 'maps',
          icon: ''
        }
      ]
    }
  },
  methods: {
    logout () {
      const { success } = authService.logout()
      if (success) {
        this.toast.success('Вы вышли из системы', {
          timeout: 5000
        })
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped>

</style>
