import { createStore } from 'vuex'
// import createPersistedState from 'vuex-persistedstate';
// import axios from 'axios';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
