<template>
  <section class="container-fluid">
    <navbar />
    Тут про финансы
  </section>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'Finance',
  components: { Navbar }

}
</script>

<style scoped>

</style>
