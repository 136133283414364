import axios from 'axios'

const projectService = {
    async list () {
        try{
          const {
            status, data
          } = await axios(process.env.VUE_APP_API_URL + '/api/project/index', {
            method: 'GET',
            headers: {
              Origin: 'https://api.belloterra.loc',
              'User-Agent': ' curl/7.51.0',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
              'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
              'Content-Type': 'application/json; charset=UTF-8'
            }
          })

          if (status === 200) {
            return data
          }

        } catch (e){
          return e
        }
    },

    async money (id) {
        try{
          const {
            status, data
          } = await axios(process.env.VUE_APP_API_URL + '/api/money-project/view?id='+id, {
            method: 'GET',
            headers: {
              Origin: 'https://api.belloterra.loc',
              'User-Agent': ' curl/7.51.0',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
              'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
              'Content-Type': 'application/json; charset=UTF-8'
            }
          })

          if (status === 200) {
            return data
          }
        } catch (e){
          return e
        }
    }
}

export default projectService
