import axios from 'axios'

const clientService = {
  async get () {
  },

  async post (dataPost) {
    const url = process.env.VUE_APP_API_URL + '/api/user/checkin'
    try {
      const {
        status,
        data
      } = await axios(url, {
        method: 'POST',
        headers: {
          Origin: 'https://api.belloterra.loc',
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
          'Content-Type': 'application/json; charset=UTF-8'
        },
        data: dataPost
      })
      if (status === 200 || data.result === true) {
        return {
          success: true
        }
      }
      return {
        success: false
      }
    } catch (error) {
      return {
        success: false
      }
    }
  }
}
export default clientService
