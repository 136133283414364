<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { maps } from '../helpers/maps'
import { street } from '../helpers/street'

const props = defineProps({
  showModal: Boolean,
  id: Number
})
const address = ref({})
const allAddress = ref([])
const streetChange = ref('')
const ownerLand = ref([])
const emits = defineEmits()

watch(() => props.id, async () => {
  if (props.id !== 0) {
    address.value = maps.find((item) => item.key === Number(props.id))
    ownerLand.value = address.value.owner
    if (ownerLand.value !== undefined) {
      allAddress.value = await sortAddress(ownerLand.value)
    }
    streetChange.value = street.find((item) => item.key === address.value.street)
  } else{
      address.value = {}
      allAddress.value = []
      ownerLand.value = []
      streetChange.value = ''
  }

})

function close () {
  emits('close', false)
}

async function sortAddress (ownerLand) {
  let newAdr = maps.filter((item) => {
    if (ownerLand.includes(item.key)) {
      let temp = street.find((itemStreet) => {
        if (itemStreet.key === item.street) {
          return itemStreet.name
        }
      })
      item.nameStreet = temp.name
      return item
    }
  })
  return newAdr
}

</script>

<template>
  <div :class="['modal',  {'is_active':props.showModal}]" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Подробнее:</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Участки №№ {{ ownerLand ? ownerLand.join(',') : props.id }}</p>
          <div v-if="allAddress.length > 0">
            <p v-for="item in allAddress" :key="item.key">
              Улица: {{ item.nameStreet }}, д.{{ item.home }}
            </p>
          </div>
          <div v-else>Улица: {{ streetChange.name }} , д. {{ address.home }} </div>
        </div>
        <div class="contact">
          <strong>Контакты:</strong> данные скрыты
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.is_active {
  display: block;
}
</style>
