<script setup>
import {maps} from '../helpers/maps'
import { street } from '../helpers/street'
import { pay } from '../helpers/snow2022-2023'
import { onMounted, ref } from 'vue'
import ModalBlank from '../components/ModalBlank'
import projectService from '../services/project.service'

const allMaps = ref([])
const project = ref([])

const land = ref(0)

onMounted(async() => {
  allMaps.value = maps
  project.value = await loadProject()
})

function loadProject(){
    return projectService.list()
}

function loadMoney(id){
   return projectService.money(id)
}

function changeStreet(key){
   allMaps.value = allMaps.value.map(function (item){
    if(item.street === key){
        item.select = true
        return item
    }
    item.select = false
    return item
  })
}

function changeHome(e){
  land.value = Number(e.target.id)
  if(land.value > 0){
    showModal.value = true
  }

}

const showModal = ref(false)

function closeModal(){
  showModal.value = false
  land.value = 0
}

async function showMoney(id) {
  if(id === 1){  //снег
    reset()
    allMaps.value = allMaps.value.map(function (item){
      let res =  pay.find(itemPay => itemPay.id === item.key)
      if(res !== undefined){
        item.select = true
        return item
      }
      item.select = false
      return item
    })
  }else {
    reset()
    let dataObject = await loadMoney(id)
    allMaps.value = allMaps.value.map(updateSelect(dataObject));
    /* allMaps.value.map(function (item) {
      let res =  dataObject.find(itemPay => itemPay.id === item.key)
      if(res !== undefined){
        item.select = true
        return item
      }
      item.select = false
      return item
    })
   */
  }
}
function updateSelect(dataObject) {
 // console.dir(dataObject)
  return function(element) {
    const res = dataObject.find(obj => obj.land_id === element.key);
    if (res !== undefined) {
      element.select = true;
    } else {
      element.select = false;
    }
    return element;
  }
}
function reset(){
  allMaps.value = allMaps.value.map(function (item){
    item.select = false
    return item
  })
}

function countChange(){
    var count = allMaps.value.filter(item => item.select === true)
    return count.length
}


</script>

<template>
  <div class="row">
    <div class="col-lg-2 col-sm-12" style="margin: 0 auto">
      <div class="card">
        <div class="card-header bg-info">
          Улицы:
        </div>
        <div class="card-body btn-group-vertical">
          <div :class="['btn', 'btn-xs', 'bg-'+itemStreet.color]" v-for="itemStreet in street" :key="itemStreet.key"
          @click="changeStreet(itemStreet.key)" >
            {{ itemStreet.name }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-8 col-sm-12"
      style="margin: 0 auto"
    >
      <div class="card">
        <div class="card-header bg-info">
          Карта поселка
        </div>
        <div class="card-body">
          <div class="map">
            <svg viewBox="0 0 480 565.44" @click="changeHome">
              <path v-for="item in allMaps" :key="item.key"
                :class="['part', {'active':item.select}]"
                :d="item.d"
                :id="item.key"
              />
            </svg>
            <img src="/img/maps.png">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-sm-12" style="margin: 0 auto">
      <div class="card">
        <div class="card-header bg-info">Инфо-табло</div>
        <div class="card-body btn-group-vertical">
          <div v-for="item in project" :key="item.id"
               class="btn btn-xs bg-cyan"
               @click="showMoney(item.id)">
              {{item.name}}
          </div>
          <div class="btn btn-xs bg-cyan"
               @click="reset">
              Сброс
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div>Участков: {{ allMaps.length }}</div>
          <div>Собственников: ?</div>
          <div>Выбрано: {{ countChange() }}</div>
        </div>
      </div>
    </div>
  </div>
  <ModalBlank :showModal="showModal" :id="land" @close="closeModal"/>
</template>

<style scoped lang="scss">
  .map{
    position: relative;
    text-align: center;
  }
  img{
    width: 100%;
    max-width: 800px;
  }
  svg {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }
  .part {
    stroke: black;
    stroke-width: 1px;
    opacity: 0.2;
    fill: #0e5b44;
    cursor: pointer ;
  }
  .part:hover{
    opacity: 0.4;
  }
  .active{
    opacity: 0.8;
    fill: #063311;
  }
</style>
