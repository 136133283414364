export const maps = [
  {
    key: 1,
    d: 'M 49.498695,437.86987 46.5121,463.96416 61.265503,467.7103 68.18158,443.70086\n' +
      'Z',
    street: 1,
    home: 1,
    select: false
  },
  {
    key: 2,
    d: 'm 68.746216,444.04267 -7.204453,23.77656 15.835236,4.53328 7.307297,-23.30961\n' +
      'z',
    street: 1,
    home: 2,
    select: false
  },
  {
    key: 3,
    d: 'm 84.951096,449.14087 -6.886612,23.51659 16.097152,4.85882 6.963564,-23.87693\n' +
      'z',
    street: 1,
    home: 3,
    select: false
  },
  {
    key: 4,
    d: 'm 101.57544,454.18861 -7.282868,23.54665 16.063198,4.78877 7.37706,-23.90671\n' +
      'z',
    street: 1,
    home: 4,
    select: false
  },
  {
    key: 5,
    d: 'm 118.46049,458.90686 -7.51379,23.81625 15.82282,5.24973 7.61181,-24.0601\n' +
      'z',
    street: 1,
    home: 5,
    select: false
  },
  {
    key: 6,
    d: 'm 134.85289,464.29266 -7.72754,23.79512 16.50215,4.51514 6.92562,-24.11002\n' +
      'z',
    street: 1,
    home: 6,
    select: false
  },
  {
    key: 7,
    d: 'm 150.86864,468.90331 -7.16457,24.07852 16.36618,4.85586 7.06072,-24.6926\n' +
      'z',
    street: 1,
    home: 7,
    select: false
  },
  {
    key: 8,
    d: 'm 167.49237,473.50095 16.4361,5.43328 -6.76255,23.85883 -16.42747,-4.87503\n' +
      'z',
    street: 1,
    home: 8,
    select: false
  },
  {
    key: 9,
    d: 'm 184.36974,479.02942 -7.01937,23.92461 17.28861,5.59805 4.60789,-25.178\n' +
      'z',
    street: 1,
    home: 9,
    select: false
  },
  {
    key: 10,
    d: 'm 211.5892,487.20393 -4.25822,24.62442 15.72995,4.68792 6.97194,-23.62546\n' +
      'z',
    street: 1,
    home: 10,
    select: false
  },
  {
    key: 11,
    d: 'm 230.63014,493.21866 -7.30028,23.45425 17.83867,5.59894 6.92829,-23.97369\n' +
      'z',
    street: 1,
    home: 11,
    select: false
  },
  {
    key: 12,
    d: 'm 248.15144,498.40013 -7.43338,24.12457 18.64003,5.00211 7.40602,-23.53735 -17.27859,-5.13913\n' +
      'z',
    street: 1,
    home: 12,
    select: false
  },
  {
    key: 13,
    d: 'm 266.91071,504.38229 -7.53194,23.56292 7.7594,2.3085 12.24405,0.22412 0.96632,-26.22899\n' +
      'z',
    street: 1,
    home: 13,
    select: false
  },
  {
    key: 14,
    d: 'm 280.20303,504.30911 -0.50602,26.42415 15.9066,0.19039\n' +
      'v -26.19773\n' +
      'z',
    street: 1,
    home: 14,
    select: false
  },
  {
    key: 15,
    d: 'm 295.87425,505.19434 -0.56976,25.80938 15.50471,0.13336 0.28887,-26.44743\n' +
      'z',
    street: 1,
    home: 15,
    select: false
  },
  {
    key: 16,
    d: 'm 311.48708,504.89062 -0.69797,26.77129 15.87489,-0.38014 0.20444,-25.94922\n' +
      'z',
    street: 1,
    home: 16,
    select: false
  },
  {
    key: 17,
    d: 'm 326.84749,505.14861 0.097,26.21086 15.1333,0.26762 0.69831,-26.35477\n' +
      'z',
    street: 1,
    home: 17,
    select: false
  },
  {
    key: 18,
    d: 'm 342.95148,505.20091 -0.55266,26.21492 10.89905,0.41832 8.76939,-2.58029 -6.26451,-23.55969\n' +
      'z',
    street: 1,
    home: 18,
    select: false
  },
  {
    key: 19,
    d: 'm 355.61702,505.23041 6.39479,24.35187 27.83392,-7.47648 -3.46943,-25.31648\n' +
      'z',
    street: 1,
    home: 19,
    select: false
  },
  {
    key: 20,
    d: '!',
    street: 1,
    home: 20,
    select: false
  },
  {
    key: 21,
    d: 'm 399.67874,493.16591 2.75076,25.03918 30.33088,-8.35807 -3.42362,-25.06646 z',
    street: 1,
    home: 21,
    select: false,
    owner: [21,22]
  },
  {
    key: 22,
    d: '!',
    street: 1,
    home: 22,
    select: false
  },
  {
    key: 23,
    d: 'm 395.67952,459.06679 2.16852,20.53879 30.04997,-7.93502 -1.80316,-15.79979 z',
    street: 2,
    home: 1,
    select: false
  },
  {
    key: 24,
    d: 'm 393.23604,440.92667 2.05635,18.10873 30.71371,-3.90929 -2.05618,-18.48439 z',
    street: 2,
    select: false
  },
  {
    key: 25,
    d: 'm 391.17529,422.02915 1.59363,18.57551 31.02203,-3.73483 -2.07897,-18.45849 z',
    street: 2,
    home: 3,
    select: false
  },
  {
    key: 26,
    d: 'm 391.13341,421.7313 -2.09261,-18.30933 30.06265,-3.7918 2.10195,18.37269\n' +
      'z',
    street: 2,
    select: false,
    home: 4
  },
  {
    key: 27,
    d: 'm 406.62453,376.17285 11.36841,8.7637 1.30726,13.95234 -30.30237,4.69035 -0.49476,-4.64554\n' +
      'z',
    street: 2,
    home: 5,
    select: false
  },
  {
    key: 28,
    d: 'm 374.59238,388.40141 17.35537,-23.31852 14.27588,10.98415 -18.62344,23.24886\n' +
      'z',
    street: 2,
    home: 6,
    select: false
  },
  {
    key: 29,
    d: 'm 374.02586,388.02156 -55.1535,-42.9053 18.22522,-22.79784 54.68373,42.57796\n' +
      'z',
    street: 2,
    home: 7,
    select: false,
    owner: [29, 30, 31, 32]
  },
  {
    key: 30,
    d: '!',
    street: 2,
    home: 8,
    select: false
  },
  {
    key: 31,
    d: '!',
    street: 2,
    home: 9,
    select: false
  },
  {
    key: 32,
    d: '!',
    street: 2,
    home: 10,
    select: false
  },
  {
    key: 33,
    d: 'm 305.97884,335.63002 18.00123,-23.75398 13.07259,10.59649 -18.07692,23.05294\n' +
      'z',
    street: 2,
    home: 11,
    select: false
  },
  {
    key: 34,
    d: 'm 310.61938,302.40203 13.29644,9.39819 -18.08261,23.58163 -12.77942,-10.07471\n' +
      'z',
    street: 2,
    home: 12,
    select: false
  },
  {
    key: 35,
    d: 'm 266.84432,304.77093 18.09213,-22.64038 25.6053,19.73479 -18.06802,23.29924\n' +
      'z',
    street: 2,
    home: 13,
    select: false,
    owner: [35,36]
  },
  {
    key: 36,
    d: '!',
    street: 2,
    home: 14,
    select: false
  },
  {
    key: 37,
    d: 'm 240.83409,285.02402 17.62666,-23.53624 25.85645,20.32149 -17.46515,23.60572\n' +
      'z',
    street: 2,
    home: 15,
    select: false,
    owner: [37,38]
  },
  {
    key: 38,
    d: '!',
    street: 2,
    home: 16,
    select: false
  },
  {
    key: 39,
    d: 'm 227.55676,274.97292 18.00658,-23.23299 12.56499,9.88168 -17.83179,23.27023\n' +
      'z',
    street: 2,
    home: 17,
    select: false
  },
  {
    key: 40,
    d: 'm 185.95815,243.14906 14.99278,-20.07292 -8.55269,-6.03235 -3.23546,-25.95313\n' +
      ' 24.26271,-3.10953 5.80399,43.35501 25.82557,19.74063 -17.32985,23.76471\n' +
      'z',
    street: 2,
    home: 18,
    select: false,
    owner: [40,41,42,43]
  },
  {
    key: 41,
    d: '!',
    street: 2,
    home: 19,
    select: false
  },
  {
    key: 42,
    d: '!',
    street: 2,
    home: 20,
    select: false
  },
  {
    key: 43,
    d: '',
    street: 2,
    home: 21,
    select: false
  },
  {
    key: 44,
    d: 'm 162.34494,224.28773 17.3997,-22.59653 10.30059,-1.22824 2.20381,15.92815 -14.63396,19.78748\n' +
      'z',
    street: 2,
    home: 22,
    select: false
  },
  {
    key: 45,
    d: 'm 162.24731,224.26221 -13.14683,-9.89865 -20.89818,-4.02629 9.95796,-52.02757 17.91895,3.81666\n' +
      ' -5.65938,27.42634 18.36328,3.34616 10.79331,9.03926\n' +
      'z',
    street: 2,
    home: 23,
    select: false,
    owner: [45,46,47]
  },
  {
    key: 46,
    d: '!',
    street: 2,
    home: 24,
    select: false
  },
  {
    key: 47,
    d: '!',
    street: 2,
    home: 25,
    select: false
  },
  {
    key: 48,
    d: 'm 156.41498,162.14924 29.7603,6.07366 3.81317,32.24654 -10.47647,1.2425 -11.22313,-9.24966\n' +
      ' -17.44508,-3.2561\n' +
      'z',
    street: 2,
    home: 26,
    select: false,
    owner: [48,49]
  },
  {
    key: 49,
    d: '!',
    street: 2,
    home: 27,
    select: false
  },
  {
    key: 50,
    d: 'm 189.45313,190.18128 24.29065,-2.43288 -8.70513,-73.19375 -84.6605,-97.72098 -13.82346,49.899004 9.4551,2.161501 ' +
      '24.29612,24.694314 24.27744,4.61008 -1.65883,10.231241 21.41415,15.1204 -6.45261,31.64445 10.04203,2.70907' +
      ' -2.2705,9.63412\n' +
      'z',
    street: 2,
    home: 28,
    select: false,
    owner: [50,51,54,55,56,57,58]
  },
  {
    key: 51,
    d: '!',
    street: 2,
    home: 29,
    select: false
  },
  {
    key: 52,
    d: 'm 140.66007,147.67999 7.93789,-42.41478 14.63243,3.07228 21.19123,15.45301 -5.96764,31.25549\n' +
      'z',
    street: 2,
    home: 30,
    select: false,
    owner: [52,53]
  },
  {
    key: 53,
    d: '!',
    street: 2,
    home: 31,
    select: false
  },
  {
    key: 54,
    d: '!',
    street: 3,
    home: 1,
    select: false
  },
  {
    key: 55,
    d: '!',
    street: 3,
    home: 2,
    select: false
  },
  {
    key: 56,
    d: '!',
    street: 3,
    home: 3,
    select: false
  },
  {
    key: 57,
    d: '!',
    street: 3,
    home: 4,
    select: false
  },
  {
    key: 58,
    d: 'm 103.72356,80.485316 -1.40507,24.138674 33.80951,6.33082 1.12736,-3.98163 -25.72295,-26.504858\n' +
      'z',
    street: 3,
    home: 5,
    select: false
  },
  {
    key: 59,
    d: 'm 102.03502,104.57411 34.37187,6.24702 -2.84778,15.45625 -33.01027,-6.33835\n' +
      'z',
    street: 3,
    home: 6,
    select: false
  },
  {
    key: 60,
    d: 'm 99.136032,136.62619 1.602308,-16.66309 32.601,6.35868 -2.96027,17.03346\n' +
      'z',
    street: 3,
    home: 7,
    select: false
  },
  {
    key: 61,
    d: 'm 99.151001,136.89964 31.134229,6.24689 -3.35573,17.31242 -29.410022,-6.00579\n' +
      'z',
    street: 3,
    home: 8,
    select: false
  },
  {
    key: 62,
    d: 'm 97.472328,154.4563 -1.005523,13.38723 -11.512459,60.46122 27.602934,5.95051 14.14797,-73.46504\n' +
      'z',
    street: 3,
    home: 9,
    select: false,
    owner: [62,63,64,65]
  },
  {
    key: 63,
    d: '!',
    street: 3,
    home: 10,
    select: false
  },
  {
    key: 64,
    d: '!',
    street: 3,
    home: 11,
    select: false
  },
  {
    key: 65,
    d: '!',
    street: 3,
    home: 12,
    select: false
  },
  {
    key: 66,
    d: 'm 85.094246,229.21746 27.417554,5.45672 -3.663,18.16071 -27.477493,-5.36866\n' +
      'z',
    street: 3,
    home: 13,
    select: false
  },
  {
    key: 67,
    d: 'm 81.585258,247.9273 27.415002,5.40142 -3.72764,19.18738 -27.482291,-5.97191\n' +
      'z',
    street: 3,
    home: 14,
    select: false
  },
  {
    key: 68,
    d: 'm 77.933723,266.84473 27.216597,5.83583 -3.8706,19.68789 -27.023327,-5.33947\n' +
      'z',
    street: 3,
    home: 15,
    select: false
  },
  {
    key: 69,
    d: 'm 74.105507,286.97844 27.317043,5.57048 -3.621525,18.62324 -27.17868,-4.92604\n' +
      'z',
    street: 3,
    home: 16,
    select: false
  },
  {
    key: 70,
    d: 'm 70.669418,306.2943 26.873154,5.07136 -3.785202,19.04881 -26.727173,-5.85928\n' +
      'z',
    street: 3,
    home: 17,
    select: false
  },
  {
    key: 71,
    d: 'm 67.315666,324.75185 26.629837,5.46225 -3.357887,15.78831 -26.588646,-4.88367\n' +
      'z',
    street: 3,
    home: 18,
    select: false
  },
  {
    key: 72,
    d: 'm 64.226593,341.55011 26.451225,5.02576 -2.606804,16.38853 -27.314582,-5.02283\n' +
      'z',
    street: 3,
    home: 19,
    select: false
  },
  {
    key: 73,
    d: 'm 60.935829,358.37878 26.503647,4.90366 -2.605988,14.77708 -26.356536,-4.9192\n' +
      'z',
    street: 3,
    home: 20,
    select: false
  },
  {
    key: 74,
    d: 'm 58.007126,376.04285 25.676803,5.22218 -2.94786,16.46402 -25.915398,-5.60809\n' +
      'z',
    street: 3,
    home: 21,
    select: false
  },
  {

    key: 75,
    d: 'm 54.416214,392.65083 26.569229,5.23093 -3.258766,16.17148 -26.615601,-4.89153\n' +
      'z',
    street: 3,
    home: 22,
    select: false
  },
  {
    key: 76,
    d: 'm 51.603687,409.35568 25.966931,5.21558 -3.545304,17.16333 -23.467682,-7.09912\n' +
      'z',
    street: 3,
    home: 23,
    select: false
  },
  {
    key: 77,
    d: 'm 87.247413,435.77581 2.582068,-15.97982 26.596619,6.11006 -4.14875,17.77391\n' +
      'z',
    street: 3,
    home: 24,
    select: false,
    owner: [77,100]
  },
  {
    key: 78,
    d: 'm 89.881805,419.73067 3.235627,-18.04822 26.587978,5.38516 -3.11641,18.0085\n' +
      'z',
    street: 3,
    home: 25,
    select: false
  },
  {
    key: 79,
    d: 'm 93.675934,401.44138 3.407509,-17.39721 26.227117,5.64658 -3.49055,17.34967\n' +
      'z',
    street: 3,
    home: 26,
    select: false
  },
  {
    key: 80,
    d: 'm 96.788132,384.08253 3.608898,-17.72001 26.19783,5.47053 -2.95375,17.32537\n' +
      'z',
    street: 3,
    home: 27,
    select: false
  },
  {
    key: 81,
    d: 'm 100.45393,366.31734 6.87748,-35.62332 26.09768,5.39523 -1.53966,11.27003 26.60996,5.20226 -3.75919,18.37874 -26.4671,-5.44879 -1.38809,6.22975\n' +
      'z',
    street: 3,
    home: 28,
    select: false,
    owner: [81,82,95]
  },
  {
    key: 82,
    d: '!',
    street: 3,
    home: 29,
    select: false
  },
  {
    key: 83,
    d: 'm 107.55764,330.45775 2.92946,-17.18172 26.37311,5.36772 -3.30386,17.41254\n' +
      'z',
    street: 3,
    home: 30,
    select: false
  },
  {
    key: 84,
    d: 'm 110.6226,313.19617 3.61749,-17.80597 26.01136,5.44535 -3.37584,17.47204\n' +
      'z',
    street: 3,
    home: 31,
    select: false
  },
  {
    key: 85,
    d: 'm 113.8073,295.19018 3.8742,-17.75027 26.0311,5.34527 -3.20088,18.19188\n' +
      'z',
    street: 3,
    home: 32,
    select: false
  },
  {
    key: 86,
    d: 'm 117.15987,277.83623 3.62104,-18.21537 26.60875,5.78655 -3.52093,17.42241\n' +
      'z',
    street: 3,
    home: 33,
    select: false
  },
  {
    key: 87,
    d: 'm 121.21136,259.5886 3.34888,-17.82153 26.02544,5.4778 -2.88471,17.93594\n' +
      'z',
    street: 3,
    home: 34,
    select: false
  },
  {
    key: 88,
    d: 'm 124.75746,241.83801 3.135,-18.04164 15.60716,2.86534 14.63963,11.55168 -7.274,9.17267\n' +
      'z',
    street: 3,
    home: 35,
    select: false
  },
  {
    key: 89,
    d: 'm 158.845,238.39525 18.61366,14.38864 -2.05409,11.75043 -26.52817,-4.77772 1.93726,-12.49867\n' +
      'z',
    street: 3,
    home: 36,
    select: false
  },
  {
    key: 90,
    d: 'm 149.08725,259.83423 26.10245,4.89191 -6.75556,34.95011 -26.35305,-5.43019\n' +
      'z',
    street: 3,
    home: 37,
    select: false,
    owner: [90,91]
  },
  {
    key: 91,
    d: '!',
    street: 3,
    home: 38,
    select: false
  },
  {
    key: 92,
    d: 'm 142.04256,294.61734 26.26342,5.15601 -3.13621,16.19979 -26.59169,-4.58252\n' +
      'z',
    street: 3,
    home: 39,
    select: false
  },
  {
    key: 93,
    d: 'm 138.80154,311.29694 26.36951,4.8847 -3.54951,18.24054 -26.57204,-5.16347\n' +
      'z',
    street: 3,
    home: 40,
    select: false
  },
  {
    key: 94,
    d: 'm 135.07631,329.3743 26.87727,5.36203 -3.55047,17.49768 -26.61205,-5.10747\n' +
      'z',
    street: 3,
    home: 41,
    select: false
  },
  {
    key: 95,
    d: '!',
    street: 3,
    home: 42,
    select: false
  },
  {
    key: 96,
    d: 'm 128.73712,366.09916 25.90872,4.69299 -3.22444,15.25077 -26.33452,-4.51104\n' +
      'z',
    street: 4,
    home: 1,
    select: false
  },
  {
    key: 97,
    d: 'm 125.49133,381.73495 26.01097,5.11051 -2.80062,15.70022 -26.49672,-4.93701\n' +
      'z',
    street: 4,
    home: 2,
    select: false
  },
  {
    key: 98,
    d: 'm 119.09245,413.50526 3.29,-15.97724 26.24606,5.24188 -3.22212,15.91255\n' +
      'z',
    street: 4,
    home: 3,
    select: false
  },
  {
    key: 99,
    d: 'm 118.69405,413.83203 25.87536,5.14313 -2.80638,15.41493 -25.42792,-5.03996\n' +
      'z',
    street: 4,
    home: 4,
    select: false
  },
  {
    key: 100,
    d: 'm 112.98579,443.76136 3.10331,-14.926 26.15852,6.2661 -3.61386,16.46192\n' +
      'z',
    street: 4,
    home: 5,
    select: false
  },
  {
    key: 101,
    d: 'm 149.77528,454.68082 1.99012,-13.66521 26.76747,5.41127 -3.04627,15.90169\n' +
      'z',
    street: 4,
    home: 6,
    select: false
  },
  {
    key: 102,
    d: 'm 152.27699,441.16954 5.8766,-31.50015 26.47471,5.35495 -5.83973,31.39705\n' +
      'z',
    street: 4,
    home: 7,
    select: false,
    owner: [102,103]
  },
  {
    key: 103,
    d: '!',
    street: 4,
    home: 8,
    select: false
  },
  {
    key: 104,
    d: 'm 158.47133,409.53566 9.19453,-49.86195 27.17569,5.18919 -10.27363,50.31642\n' +
      'z',
    street: 4,
    home: 9,
    select: false,
    owner: [104,105,106]
  },
  {
    key: 105,
    d: '!',
    street: 4,
    home: 10,
    select: false
  },
  {
    key: 106,
    d: '!',
    street: 4,
    home: 11,
    select: false
  },
  {
    key: 107,
    d: 'm 167.51115,359.63541 3.66748,-18.28153 26.88298,5.18254 -3.64512,18.09458\n' +
      'z',
    street: 4,
    home: 12,
    select: false
  },
  {
    key: 108,
    d: 'm 171.20612,341.19278 3.89785,-18.57436 26.02349,5.43569 -2.79175,18.45448\n' +
      'z',
    street: 4,
    home: 13,
    select: false
  },
  {
    key: 109,
    d: 'm 175.44402,322.72986 3.42887,-18.331 25.94387,4.94967 -3.35138,18.7483\n' +
      'z',
    street: 4,
    home: 14,
    select: false
  },
  {
    key: 110,
    d: 'm 178.47934,303.75182 7.48582,-39.38954 4.25295,-1.61854 25.60097,19.62584 -7.15125,8.14652 -3.76051,19.14674\n' +
      'z',
    street: 4,
    home: 15,
    select: false,
    owner: [110,111]
  },
  {
    key: 111,
    d: '!',
    street: 4,
    home: 16,
    select: false
  },
  {
    key: 112,
    d: 'm 216.14749,282.94498 19.04442,14.66201 -2.82677,12.9909 -26.55317,-4.94768 3.25412,-15.12409\n' +
      'z',
    street: 4,
    home: 17,
    select: false
  },
  {
    key: 113,
    d: 'm 205.9584,306.16551 26.33381,4.8725 -7.46013,40.94672 -26.41558,-5.48139\n' +
      'z',
    street: 4,
    home: 18,
    select: false,
    owner: [113,114]
  },
  {
    key: 114,
    d: '!',
    street: 4,
    home: 19,
    select: false
  },
  {
    key: 115,
    d: 'm 198.50447,347.21806 25.86873,5.18122 -6.66234,36.59295 -26.62686,-5.62983\n' +
      'z',
    street: 4,
    home: 20,
    select: false,
    owner: [115,116]
  },
  {
    key: 116,
    d: '!',
    street: 4,
    home: 21,
    select: false
  },
  {
    key: 117,
    d: 'm 191.3969,383.72348 -6.48943,31.25211 26.46178,5.75396 6.05087,-31.73743\n' +
      'z',
    street: 4,
    home: 22,
    select: false,
    owner: [117,118]
  },
  {
    key: 118,
    d: '!',
    street: 4,
    home: 23,
    select: false
  },
  {
    key: 119,
    d: 'm 185.13557,415.4185 25.93032,5.42842 -6.23583,32.55021 -26.31439,-4.62491\n' +
      'z',
    street: 5,
    home: 1,
    select: false,
    owner: [119,120]
  },
  {
    key: 120,
    d: '!',
    street: 5,
    home: 2,
    select: false
  },
  {
    key: 121,
    d: 'm 178.57651,448.87601 26.20369,5.31604 -2.86478,15.74737 -26.08519,-7.53969\n' +
      'z',
    street: 5,
    home: 3,
    select: false
  },
  {
    key: 122,
    d: 'm 214.52054,474.39482 5.37756,-29.1376 26.2605,5.73517 -6.13004,31.08482\n' +
      'z',
    street: 5,
    home: 4,
    select: false,
    owner: [122,123]
  },
  {
    key: 123,
    d: '!',
    street: 5,
    home: 5,
    select: false
  },
  {
    key: 124,
    d: 'm 219.99829,445.05377 5.82434,-29.92583 26.31809,5.3756 -5.69794,30.24068\n' +
      'z',
    street: 5,
    home: 6,
    select: false,
    owner: [124,125]
  },
  {
    key: 125,
    d: '!',
    street: 5,
    home: 7,
    select: false
  },
  {
    key: 126,
    d: 'm 225.39772,415.09694 6.01328,-30.81165 53.20877,10.49161 -6.23389,33.26911 -26.32237,-5.12568 0.21621,-2.98143\n' +
      'z',
    street: 5,
    home: 8,
    select: false,
    owner: [126,127,135,136]
  },
  {
    key: 127,
    d: '!',
    street: 5,
    home: 9,
    select: false
  },
  {
    key: 128,
    d: 'm 231.1328,384.16928 3.85104,-17.39572 26.58585,5.34532 -3.40354,17.33154\n' +
      'z',
    street: 5,
    home: 10,
    select: false
  },
  {
    key: 129,
    d: 'm 235.11807,366.81186 3.30907,-17.46802 25.97919,5.16463 -2.88407,17.8148\n' +
      'z',
    street: 5,
    home: 11,
    select: false
  },
  {
    key: 130,
    d: 'm 238.50383,349.32027 3.36299,-18.08153 25.95616,5.48928 -2.96788,17.53061\n' +
      'z',
    street: 5,
    home: 12,
    select: false
  },
  {
    key: 131,
    d: 'm 241.75345,331.29929 3.99914,-21.1614 4.36008,-1.15287 25.1998,19.22814 -7.10382,7.84354\n' +
      'z',
    street: 5,
    home: 13,
    select: false
  },
  {
    key: 132,
    d: 'm 275.71669,328.61972 19.27097,14.58455 -6.8205,34.0995 -26.12552,-4.68093 6.2527,-35.77412\n' +
      'z',
    street: 5,
    home: 14,
    select: false,
    owner: [132,133]
  },
  {
    key: 133,
    d: '!',
    street: 5,
    home: 15,
    select: false
  },
  {
    key: 134,
    d: 'm 262.23424,372.18764 25.72131,4.56032 -3.1207,18.34651 -26.04141,-6.01856\n' +
      'z',
    street: 5,
    home: 16,
    select: false
  },
  {
    key: 135,
    d: '!',
    street: 5,
    home: 17,
    select: false
  },
  {
    key: 136,
    d: '!',
    street: 5,
    home: 18,
    select: false
  },
  {
    key: 137,
    d: 'm 251.68216,423.06629 26.60869,5.59377 -2.66788,14.17597 -26.37007,-4.98238\n' +
      'z',
    street: 5,
    home: 19,
    select: false
  },
  {
    key: 138,
    d: 'm 249.4819,438.29355 25.70081,5.00954 -2.45748,14.57797 -27.21516,-4.33326\n' +
      'z',
    street: 5,
    home: 20,
    select: false
  },
  {
    key: 139,
    d: 'm 246.22411,453.33608 25.91619,5.34465 -5.37492,31.24604 -26.06824,-7.48416\n' +
      'z',
    street: 6,
    home: 1,
    select: false,
    owner: [139,140]
  },
  {
    key: 140,
    d: '!',
    street: 6,
    home: 2,
    select: false
  },
  {
    key: 141,
    d: 'm 277.98141,490.40843 3.18203,-19.58525 27.39946,5.41072 0.5,14.94763\n' +
      'z',
    street: 6,
    home: 3,
    select: false
  },
  {
    key: 142,
    d: 'm 281.10379,470.55847 3.58128,-18.03355 26.39443,5.24484 0.0116,5.75156 -2.16782,12.66145\n' +
      'z',
    street: 6,
    home: 4,
    select: false
  },
  {
    key: 143,
    d: 'm 285.20291,452.85294 3.21448,-18.60629 26.49545,5.13477 -3.84915,18.0539\n' +
      'z',
    street: 6,
    home: 5,
    select: false
  },
  {
    key: 144,
    d: 'm 311.55907,464.71222 -0.31018,-6.89024 3.91688,-18.34342 19.02265,3.36446 0.11242,21.50314\n' +
      'z',
    street: 6,
    home: 6,
    select: false
  },
  {
    key: 145,
    d: 'm 311.89476,464.78038 -3.50009,11.79478 0.83549,14.81762 16.04551,0.32827 0.26066,-25.99554\n' +
      'z',
    street: 6,
    home: 7,
    select: false
  },
  {
    key: 146,
    d: 'm 325.76663,465.01242 -0.36032,26.74266 16.94211,0.56049 -0.10982,-26.92517\n' +
      'z',
    street: 6,
    home: 8,
    select: false
  },
  {
    key: 147,
    d: 'm 342.77596,465.36554 -0.46662,26.95479 11.60265,-0.22763 8.94958,-2.36589 -7.36328,-26.20264\n' +
      'z',
    street: 6,
    home: 9,
    select: false
  },
  {
    key: 148,
    d: 'm 357.89334,471.72777 5.38939,18.09529 21.8369,-6.42311 -1.29375,-15.2656\n' +
      'z',
    street: 6,
    home: 10,
    select: false
  },
  {
    key: 149,
    d: 'm 357.62395,471.16866 -1.978,-7.83233 -0.74498,-8.20941 26.25082,-3.06105 2.14225,16.04453\n' +
      'z',
    street: 6,
    home: 11,
    select: false
  },
  {
    key: 150,
    d: 'm 354.5092,454.67001 -3.71434,-32.20231 26.22379,-3.4218 4.33149,32.69488\n' +
      'z',
    street: 6,
    home: 12,
    select: false,
    owner: [150,152]
  },
  {
    key: 151,
    d: 'm 336.45236,433.01115 14.78021,-1.8823 4.41248,32.26014 -13.41084,1.80063 -7.72428,-0.39572 0.47271,-21.91498\n' +
      'z',
    street: 6,
    home: 13,
    select: false
  },
  {
    key: 152,
    d: '!',
    street: 6,
    home: 14,
    select: false
  },
  {
    key: 153,
    d: 'm 377.70599,419.06203 -1.77801,-13.06174 -43.36565,-33.21826 -6.53612,7.19948 -8.96826,48.43911 19.20072,4.13266 15.29536,-1.60778 -1.07226,-8.66019\n' +
      'z',
    street: 6,
    home: 15,
    select: false,
    owner: [153,154,155,160]
  },
  {
    key: 154,
    d: '!',
    street: 6,
    home: 16,
    select: false
  },
  {
    key: 155,
    d: '!',
    street: 6,
    home: 17,
    select: false
  },
  {
    key: 156,
    d: 'm 290.4866,423.43803 12.87422,-68.30127 4.67414,-1.6398 23.91542,18.86936 -5.90039,7.32623 -9.30796,49.13467\n' +
      'z',
    street: 6,
    home: 18,
    select: false,
    owner: [156,157,158,159]
  },
  {
    key: 157,
    d: '!',
    street: 6,
    home: 19,
    select: false
  },
  {
    key: 158,
    d: '!',
    street: 6,
    home: 20,
    select: false
  },
  {
    key: 159,
    d: '!',
    street: 6,
    home: 21,
    select: false
  },
  {
    key: 160,
    d: '!',
    street: 6,
    home: 22,
    select: false
  }
]


