export const pay = [
  {
    id: 1,
    sum: 1000
  },
  {
    id: 7,
    sum: 1000
  },
  {
    id: 9,
    sum: 1000
  },
  {
    id: 10,
    sum: 1000
  },
  {
    id: 12,
    sum: 1000
  },
  {
    id: 13,
    sum: 1000
  },
  {
    id: 14,
    sum: 500
  },
  {
    id: 17,
    sum: 500
  },
  {
    id: 18,
    sum: 500
  },
  {
    id: 21,
    sum: 1000
  },
  {
    id: 26,
    sum: 1000
  },
  {
    id: 34,
    sum: 1000
  },
  {
    id: 35,
    sum: 1000
  },
  {
    id: 39,
    sum: 1000
  },
  {
    id: 44,
    sum: 1000
  },
  {
    id: 45,
    sum: 500
  },
  {
    id: 61,
    sum: 1000
  },
  {
    id: 62,
    sum: 1000
  },
  {
    id: 69,
    sum: 1000
  },
  {
    id: 70,
    sum: 500
  },
  {
    id: 73,
    sum: 1000
  },
  {
    id: 77,
    sum: 1000
  },
  {
    id: 80,
    sum: 1000
  },
  {
    id: 81,
    sum: 965
  },
  {
    id: 85,
    sum: 1000
  },
  {
    id: 87,
    sum: 1000
  },
  {
    id: 88,
    sum: 1000
  },
  {
    id: 89,
    sum: 1000
  },
  {
    id: 96,
    sum: 1000
  },
  {
    id: 104,
    sum: 1000
  },
  {
    id: 108,
    sum: 1000
  },
  {
    id: 112,
    sum: 1000
  },
  {
    id: 113,
    sum: 1000
  },
  {
    id: 115,
    sum: 1000
  },
  {
    id: 124,
    sum: 1000
  },
  {
    id: 128,
    sum: 1000
  },
  {
    id: 129,
    sum: 1000
  },
  {
    id: 137,
    sum: 1000
  },
  {
    id: 138,
    sum: 1000
  },
  {
    id: 141,
    sum: 1000
  },
  {
    id: 142,
    sum: 1000
  },
  {
    id: 143,
    sum: 1000
  },
  {
    id: 144,
    sum: 1000
  },
  {
    id: 146,
    sum: 500
  },
  {
    id: 156,
    sum: 1000
  },

]
