import axios from 'axios'

const basicInfoService = {
  async getAllStreet () {
    try {
      const {
        status,
        data
      } = await axios(process.env.VUE_APP_API_URL + '/api/street/index', {
        method: 'GET',
        headers: {
          Origin: 'https://belloterra.ru',
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
          'Content-Type': 'application/json; charset=UTF-8'
        }
      })
      if (status === 200) {
        return data
      }
    } catch (error) {
      return error
    }
  },

  async getAllHome (number) {
    try {
      const {
        status,
        data
      } = await axios(process.env.VUE_APP_API_URL + '/api/street/home?numStreet=' + number, {
        method: 'GET',
        headers: {
          Origin: 'https://belloterra.ru',
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
          'Content-Type': 'application/json; charset=UTF-8'
        }
      })
      if (status === 200) {
        return data
      }
    } catch (error) {
      return error
    }
  }
}

export default basicInfoService
