import axios from 'axios'

const authService = {
  getToken () {
    return localStorage.getItem('token')
  },
  async login (dataPost) {
    try {
      const {
        status,
        data
      } = await axios(process.env.VUE_APP_API_URL + '/api/user/login', {
        method: 'POST',
        headers: {
          Origin: 'https://belloterra.ru',
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
          'Content-Type': 'application/json; charset=UTF-8'
        },
        data: dataPost
      })
      if (status === 200 && data.username) {
        localStorage.setItem('token', data.access_token)
        return {
          success: true
        }
      }
      return {
        success: false
      }
    } catch (error) {
      return {
        success: false
      }
    }
  },
  logout () {
    localStorage.removeItem('token')
    return {
      success: true
    }
  }

}

export default authService
