<script>
import { IMaskDirective } from 'vue-imask'
import { useToast } from 'vue-toastification'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, minLength } from '@vuelidate/validators'
import authService from '../services/auth.service'

export default {
  name: 'Login',
  directives: {
    imask: IMaskDirective
  },
  setup () {
    const toast = useToast()
    const v$ = useVuelidate()
    return {
      v$,
      toast
    }
  },
  data () {
    return {
      regNumber: '',
      phone: '',
      code: '',
      byRefAccept: 'init',
      byRefComplete: 'init',
      maskReg: {
        mask: '00:00:000000:0000',
        lazy: true
      },
      maskPhone: {
        mask: '+{7}(000) 000-0000',
        lazy: true
      }
    }
  },
  validations () {
    return {
      phone: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(17)
      },
      code: {
        required
      }
    }
  },
  methods: {
    async login () {
      if (this.v$.$invalid) {
        this.v$.$touch()
        return
      }
      const data = {
        phone: this.byRefComplete.unmaskedValue,
        password: this.code
      }
      const result = await authService.login(data);
      if (result.success === false) {
        this.toast.error('Пользователя с такими данными не существует', {
          timeout: 5000
        })
      } else {
        this.$router.push('/')
        this.toast.success('Авторизация прошла успешно', {
          timeout: 5000
        })
      }
    },
    onAccept (e) {
      this.byRefAccept = e.detail
    },
    onComplete (e) {
      this.byRefComplete = e.detail
    }
  }
}
</script>

<template>
  <main class="login-page">
    <div class="login-box form-signin m-auto">
      <div class="card card-info">
        <div class="card-header">
          <h3 class="card-title">
            Авторизация
          </h3>
        </div>
        <form @submit.prevent="login">
          <div class="card-body">
            <div class="input-group mb-3">
              <div class="card-subtitle">
                Введите телефон
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-phone" />
                  </span>
                </div>
                <input
                  class="form-control"
                  v-model="phone"
                  v-imask="maskPhone"
                  name="phone"
                  ref="phone"
                  placeholder="+7"
                  @accept="onAccept"
                  @complete="onComplete"
                >
                <div
                  class="input-errors"
                  v-if="v$.phone.$dirty && v$.phone.required.$invalid"
                >
                  <div
                    class="error-msg"
                  >
                    Поле обязательно к заполнению
                  </div>
                </div>
                <div
                  class="input-errors"
                  v-if="v$.phone.$dirty && v$.phone.minLength.$invalid"
                >
                  <div
                    class="error-msg"
                  >
                    Телефон из 10 цифр
                  </div>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <div class="card-subtitle">
                Введите пароль
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-code" />
                  </span>
                </div>
                <input
                  class="form-control"
                  v-model="code"
                  name="code"
                  placeholder="*******"
                  type="password"
                >
                <div
                  class="input-errors"
                  v-if="v$.code.$dirty && v$.code.required.$invalid"
                >
                  <div
                    class="error-msg"
                  >
                    Поле обязательно к заполнению
                  </div>
                </div>
                <div
                  class="input-errors"
                  v-if="v$.phone.$dirty && v$.phone.minLength.$invalid"
                >
                  <div
                    class="error-msg"
                  >
                    Телефон из 10 цифр
                  </div>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <button class="btn btn-info w-100">
                Войти
              </button>
            </div>
          </div>
        </form>
        <div class="card-footer">
          <router-link
            :to="{name: 'NewCheck'}"
            class="btn btn-warning w-80"
          >
            Запросить доступ
          </router-link>
          <div style="margin-top: 7rem">
            <router-link :to="{name: 'Maps'}"
                         class="btn w-100 bg-info">
                Карта поселка
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
.input-errors {
  width: 100%;
}

.error-msg {
  width: 100%;
  color: red;
}
</style>
