<template>
  <section class="container-fluid">
    <navbar />
    Лист голосовалок, за что-то
  </section>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'Voting',
  components: { Navbar }
}
</script>

<style scoped>

</style>
